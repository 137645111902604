import React from "react";
import Image from "next/image";
import worldMap from "@/public/img/home/hayat-world-map.svg";

const MapSection = () => {
  return (
    <section className="flex w-full flex-col items-center justify-start gap-8 overflow-hidden pb-8">
      <div className="container flex w-full flex-col items-center justify-start gap-10 px-4 pt-8 text-center">
        <div className="flex flex-col items-center justify-start gap-5 self-stretch">
          <h2 className="self-stretch text-3xl font-bold leading-9 text-primary-700">
            Our Global Presence
          </h2>
          <div className="flex items-center justify-start gap-2 self-stretch">
            <div className="text-base font-semibold text-auto-black/60">
              AlHayat Foodstuff spans the globe, strategically anchored in
              Poland—a gateway to the EU and beyond. From Qatar to Egypt and
              Poland, our network ensures reliable distribution and quality
              excellence.
            </div>
          </div>
        </div>
      </div>
      <Image className="max-h-[500px] w-full" alt="" src={worldMap} />
    </section>
  );
};

export default MapSection;
