import React from "react";
import sectionImage from "@/public/img/home/port-forklift.jpg";
import Image from "next/image";
import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { m } from "framer-motion";
import { useTranslation } from "next-i18next";

const AboutSection = () => {
  const { i18n } = useTranslation();
  const rtl = i18n.dir() === "rtl";
  return (
    <section className="w-full overflow-x-hidden bg-auto-black/5">
      <div className="flex w-full flex-col md:container md:flex-row-reverse">
        <m.div
          className="relative h-80 md:h-auto md:w-full"
          initial={{
            transform: rtl ? "translateX(-25%)" : "translateX(25%)",
            opacity: 0,
          }}
          whileInView={{ transform: "translateX(0)", opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <Image
            className="absolute object-cover object-center"
            alt=""
            src={sectionImage}
            fill
            placeholder="blur"
            sizes="(min-width: 600px) 900px, (min-width: 900px) 450px, (min-width: 1200px) 600px, 600px"
          />
        </m.div>
        <div className="container inline-flex flex-col items-start justify-center gap-8 px-4 py-4 md:max-w-full md:grow-[3]">
          <div className="flex flex-col items-center justify-start gap-5 self-stretch px-4 text-center sm:text-start">
            <m.div
              className="inline-flex items-start justify-start self-stretch"
              initial={{
                transform: rtl ? "translateX(25%)" : "translateX(-25%)",
                opacity: 0,
              }}
              whileInView={{ transform: "translateX(0)", opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              viewport={{ once: true }}
            >
              <h2 className="shrink grow basis-0 text-3xl font-bold leading-9 text-primary-700">
                About AlHayat Foodstuff
              </h2>
            </m.div>
            <m.div
              className="flex flex-col items-start justify-start gap-2 self-stretch"
              initial={{
                transform: rtl ? "translateX(25%)" : "translateX(-25%)",
                opacity: 0,
              }}
              whileInView={{ transform: "translateX(0)", opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              viewport={{ once: true }}
            >
              <div className="inline-flex items-center justify-start gap-2 self-stretch">
                <p className="shrink grow basis-0 text-base font-medium leading-normal text-auto-black/65">
                  At AlHayat Foodstuff, we've been dedicated to delivering
                  quality frozen vegetables since our inception in 2009. Our
                  journey began in Qatar, and today, we stand as a leader in
                  mass production and distribution with offices and factories in
                  Poland, Egypt and Qatar. Committed to transparency,
                  sustainability, and excellence, we source directly from
                  farmers, ensuring unparalleled quality control. Explore our
                  story and discover why we are your ideal partner.
                </p>
              </div>
            </m.div>
            <m.div
              className="py-4"
              initial={{
                transform: rtl ? "translateX(25%)" : "translateX(-25%)",
                opacity: 0,
              }}
              whileInView={{ transform: "translateX(0)", opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              viewport={{ once: true }}
            >
              <Link
                className="group flex items-center justify-center gap-1 rounded"
                href="/about"
              >
                <div className="text-base font-medium leading-normal text-blue-500">
                  Learn More About Us
                </div>
                <ArrowRightIcon className="h-6 w-6 fill-blue-500 transition-transform duration-100 will-change-transform group-hover:translate-x-1" />
              </Link>
            </m.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
