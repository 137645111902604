import React from "react";
import Image from "next/image";
import Link from "next/link";
import privateLabelImage from "@/public/img/products/private-label/private-label.png";
import qualityControlImage from "@/public/img/home/quality-control.jpg";
import dealImage from "@/public/img/home/deal.jpg";
import { m } from "framer-motion";
import { useTranslation } from "next-i18next";

const WhyUsSection = () => {
  const { i18n } = useTranslation();
  const rtl = i18n.dir() === "rtl";
  const containerAnimation = {
    hidden: {},
    show: {
      // opacity: 1,
      transition: {
        // duration: 0.3,
        delayChildren: 0.2,
        // ease: "easeOut",
        // staggerChildren: 0.2,
      },
    },
  };

  const itemAnimation = (i) => {
    const hidden =
      i === 0
        ? { x: rtl ? 100 : -100, opacity: 0 }
        : i === 2
          ? { x: rtl ? -100 : 100, opacity: 0 }
          : { y: 100, opacity: 0 };
    return {
      hidden,
      show: {
        x: 0,
        y: 0,
        opacity: 100,
        transition: { duration: 0.5, ease: "easeOut" },
      },
    };
  };

  return (
    <section className="relative flex w-full overflow-hidden">
      <div className="container inline-flex flex-col items-center justify-start gap-8 px-4 py-16">
        <h2 className="self-stretch text-center text-3xl font-bold leading-9 text-primary-700">
          Why AlHayat Foodstuff?
        </h2>
        <m.div
          className="grid grid-cols-1 gap-8 px-4 md:grid-cols-3 md:gap-4 lg:gap-8"
          variants={containerAnimation}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          <m.div
            className="card flex flex-col items-start justify-start gap-4 self-stretch"
            variants={itemAnimation(0)}
          >
            <div className="relative h-60 w-full overflow-hidden bg-gradient-to-b from-white/30 to-black/20 p-4">
              <Image
                className="card-image h-full w-full object-contain object-center drop-shadow-[10px_5px_7px_rgba(0,0,0,0.4)]"
                src={privateLabelImage}
                alt=""
                placeholder="blur"
                sizes="(max-size: 600px) 361px, (max-size: 900px) 536px, (max-size: 1200px) 268px, 361px"
              />
            </div>
            <div className="card-content flex flex-col items-start justify-start gap-4 self-stretch px-6">
              <div className="flex flex-col items-start justify-start gap-3 self-stretch">
                <div className="self-stretch text-2xl font-bold leading-loose text-primary-700">
                  Private Label Solutions
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-auto-black/65">
                  Tailor our premium frozen vegetables to your brand with our
                  private label solutions. Your brand, our quality – the perfect
                  partnership for success.
                </div>
              </div>
            </div>
          </m.div>
          <m.div
            className="card flex flex-col items-start justify-start gap-4 self-stretch  pb-6 "
            variants={itemAnimation(1)}
          >
            <div className="card-image relative h-60 w-full ">
              <Image
                className="h-full w-full object-cover object-center"
                src={qualityControlImage}
                alt=""
                placeholder="blur"
                sizes="(max-size: 600px) 361px, (max-size: 900px) 536px, (max-size: 1200px) 268px, 361px"
              />
            </div>
            <div className="card-content flex flex-col items-start justify-start gap-4 self-stretch px-6">
              <div className="flex flex-col items-start justify-start gap-3 self-stretch">
                <div className="self-stretch text-2xl font-bold leading-loose text-primary-700">
                  Uncompromising Quality Control
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-auto-black/65">
                  Quality is non-negotiable. From farm to market, our commitment
                  to rigorous quality control ensures that you receive only the
                  best. Taste the excellence in every bite.
                </div>
              </div>
            </div>
          </m.div>
          <m.div
            className="card flex flex-col items-start justify-start gap-4 self-stretch pb-6 "
            variants={itemAnimation(2)}
          >
            <div className="card-image relative h-60 w-full ">
              <Image
                className="h-full w-full object-cover object-center"
                src={dealImage}
                alt=""
                placeholder="blur"
                sizes="(max-size: 600px) 361px, (max-size: 900px) 536px, (max-size: 1200px) 268px, 361px"
              />
            </div>
            <div className="card-content flex flex-col items-start justify-start gap-4 self-stretch px-6">
              <div className="flex flex-col items-start justify-start gap-3 self-stretch">
                <div className="self-stretch text-2xl font-bold leading-loose text-primary-700">
                  Get Price Quotes Today
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-auto-black/65">
                  Curious about our competitive pricing? Inquire now and receive
                  personalized price quotes for your specific product needs.
                  Your gateway to cost-effective excellence.
                </div>
              </div>
            </div>
          </m.div>
        </m.div>
        <Link href="/inquiry" className="button group w-80">
          <div>Request a Quote</div>
          {/* <ArrowRightIcon className="h-6 w-6 fill-white transition-transform duration-100 will-change-transform group-hover:translate-x-1" /> */}
        </Link>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 225.14"
        preserveAspectRatio="none"
        className="absolute bottom-0 left-0 right-0 -z-10 hidden h-1/2 w-full fill-accent1-50/50 dark-theme:fill-white/5 md:block lg:h-3/5"
      >
        <path d="M500,225.14H0V0C134,151.92,361.83,139.8,500,0Z" />
      </svg>
    </section>
  );
};

export default WhyUsSection;
