import React from "react";
import ContactForm from "../contact/contact-form";
import { m } from "framer-motion";

const ContactSection = () => {
  return (
    <section className="flex w-full flex-col items-center justify-start gap-8 overflow-hidden pb-8 sm:bg-auto-black/5">
      <div className="container flex w-full flex-col items-center justify-start gap-8 px-4 pt-8 text-center sm:text-start md:grid md:grid-cols-2">
        <m.h2
          className="self-stretch text-3xl font-bold leading-9 text-primary-700"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
        >
          Have questions or inquiries? Reach out to us, and let's start a
          conversation.
        </m.h2>
        <m.div
          className="sm:card relative w-full sm:p-10 md:row-span-2"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
        >
          <ContactForm omitSubject omitCompany omitCountry omitPhone />
        </m.div>
        <m.div
          className="flex w-full flex-col items-start justify-start gap-2 text-start md:justify-end md:self-stretch"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
        >
          <div className="self-stretch text-base font-medium leading-normal text-auto-black/60">
            Need to reach quicker?
          </div>
          <div className="flex flex-col items-start justify-start gap-2 self-stretch">
            <div className="self-stretch text-2xl font-medium leading-loose text-auto-black/80">
              +48 514 499 212
            </div>
            <div className="self-stretch text-2xl font-medium leading-loose text-auto-black/80">
              info.pl@alhayatfoodstuff.com
            </div>
          </div>
        </m.div>
      </div>
    </section>
  );
};

export default ContactSection;
