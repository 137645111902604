import React from "react";
import sectionImage from "@/public/img/home/products.jpg";
import Image from "next/image";
import Link from "next/link";
import { m } from "framer-motion";

const ProductsSection = () => {
  return (
    <section className=" relative flex min-h-[477px] w-screen flex-col items-center justify-center gap-2 overflow-hidden bg-black bg-opacity-60 p-4 sm:h-[513px] lg:h-[745px]">
      <Image
        className="absolute inset-0 -z-10 object-cover object-center"
        src={sectionImage}
        alt=""
        fill
        placeholder={"blur"}
        sizes="(max-width: 600px) 600px, (max-width: 900px) 900px, (max-width: 1200px) 1200px, (max-width: 1500px) 1500px, 2000px"
      />
      <div className="container flex h-full flex-col items-center justify-center gap-5 self-stretch text-center">
        <m.div
          className="flex flex-col items-center justify-center gap-5 self-stretch text-center"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div className="self-stretch text-5xl font-bold leading-[60px] text-lime-200">
            Our Premium Frozen Produce
          </div>
          <div className="self-stretch text-base font-medium leading-normal text-white">
            Discover a world of flavor with AlHayat Foodstuff's premium frozen
            produce. From green peas to succulent strawberries, our diverse
            range promises quality and freshness. Elevate your offerings with
            our premium frozen produce, sourced and produced with a commitment
            to excellence.
          </div>
          <Link href="/products" className="button">
            Explore Our Range
          </Link>
        </m.div>
      </div>
    </section>
  );
};

export default ProductsSection;
