import React from "react";
import { m } from "framer-motion";

const defaultClasses =
  "h-12 w-full py-3 rounded border border-auto-black/30 bg-transparent px-4 placeholder:text-auto-black/30 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary";

const TextField = React.forwardRef(
  (
    {
      label,
      helperText,
      error,
      animate,
      required,
      multiline,
      disabled,
      className,
      ...restProps
    },
    ref,
  ) => {
    var finalClassName = "";
    if (className) {
      const classes = className.split(" ");
      // console.log(classes);
      var finalClasses = defaultClasses.split(" ");
      for (const cls of classes) {
        // console.log(cls);
        if (cls.includes("h-") || cls.includes("w-") || cls.includes("text-")) {
          //remove the class with the same beginning and add the new class
          finalClasses = finalClasses.filter(
            (c) => !c.includes(cls.split("-")[0]),
          );
          finalClasses.push(cls);
        } else {
          finalClasses.push(cls);
        }
      }
      finalClassName = finalClasses.join(" ");
    } else {
      finalClassName = defaultClasses;
    }

    const WrapperComponent = animate ? m.div : "div";
    const Element = multiline ? "textarea" : "input";

    return (
      <WrapperComponent
        className={`text-start ${error ? "text-red-500" : "text-auto-black/60"}`}
        animate={animate}
      >
        <label
          className="text-sm data-[disabled=true]:opacity-40"
          data-disabled={disabled}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
          <Element
            ref={ref}
            data-error={error}
            required={required}
            disabled={disabled}
            className={`${finalClassName} disabled:bg-auto-black/10 data-[error=true]:border-red-500 data-[error=true]:focus:ring-red-500`}
            {...restProps}
          />
          {error && !!helperText && <div className="text-xs">{helperText}</div>}
        </label>
      </WrapperComponent>
    );
  },
);

export default TextField;
export { defaultClasses };
