import React from "react";
import heroImageLandscape from "@/public/img/home/hero-image.jpg";
import heroImagePortrait from "@/public/img/home/hero-image-portrait.jpg";
// import Image from "next/image";
import { getImageProps } from "next/image";
import Link from "next/link";
import { m } from "framer-motion";
import AnalyticsContext from "@/utils/analytics-provider";

const HeroSection = () => {
  const analytics = React.useContext(AnalyticsContext);

  const imgCommon = {
    className: "absolute inset-0 -z-10 object-cover object-center",
    quality: 75,
    alt: "",
    fill: true,
    priority: true,
    placeholder: "blur",
    quality: 75,
  };
  const {
    props: { srcSet: portraitSrcSet },
  } = getImageProps({
    ...imgCommon,
    src: heroImagePortrait,
  });
  const {
    props: { srcSet: landscapeSrcSet, ...rest },
  } = getImageProps({
    ...imgCommon,
    src: heroImageLandscape,
  });

  return (
    <section className="relative h-[calc(100vh-4rem)] min-h-96 w-screen overflow-hidden bg-gradient-to-b from-black/30 via-black/50 to-white/40 ">
      <m.div
        className="absolute inset-0 -z-10 bg-black/50"
        initial={{ transform: "scale(1.1)" }}
        animate={{ transform: "scale(1)" }}
        transition={{ duration: 20, ease: "easeOut" }}
      >
        {/* <Image
          className="absolute inset-0 -z-10 object-cover object-center"
          src={heroImage}
          alt=""
          fill
          priority
          placeholder={"blur"}
          quality={75}
          sizes="(max-width: 450px) 1000px, (max-width: 600px) 1200px, (max-width: 900px) 1200px, (max-width: 1200px) 1200px, (max-width: 1500px) 1500px, 2000px"
        /> */}
        <picture>
          <source
            media="(orientation: portrait)"
            srcSet={portraitSrcSet}
            sizes="100vw"
          />
          <source
            media="(orientation: landscape)"
            srcSet={landscapeSrcSet}
            sizes="100vw"
          />
          <img
            {...rest}
            sizes="(max-width: 450px) 1000px, (max-width: 600px) 1200px, (max-width: 900px) 1200px, (max-width: 1200px) 1200px, (max-width: 1500px) 1500px, 2000px"
          />
        </picture>
      </m.div>

      <div className="container flex h-full w-full flex-col items-center justify-center gap-24 p-4">
        <div className="flex flex-col items-end justify-center gap-8 self-stretch">
          <h1 className="self-stretch text-center text-3xl font-bold leading-9 text-white sm:text-4xl md:text-5xl lg:text-6xl">
            AlHayat Foodstuff: Your Premier Partner for Quality Frozen
            Vegetables Worldwide
          </h1>
          <div className="inline-flex items-center justify-center self-stretch">
            <div className="shrink grow basis-0 text-center text-base font-medium leading-normal text-white sm:text-lg lg:text-xl">
              Experience Unmatched Quality, Sustainability, and Flexibility with
              Our Private Label Solutions.
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-2 pb-12">
          <Link
            className="button w-60"
            href="/contact"
            onClick={() =>
              analytics.action("click_become_customer_home_hero_section")
            }
          >
            <div className="text-base font-medium leading-normal text-white">
              Become A Customer
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
