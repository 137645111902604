import { useEffect, useState, useContext } from "react";
import { m } from "framer-motion";
import TextField from "../common/text-field";
import { useForm, useFormState } from "react-hook-form";
import { useAnimationControls } from "framer-motion";
import AnalyticsContext from "@/utils/analytics-provider";

import dynamic from "next/dynamic";

const Spinner = dynamic(() => import("../common/spinner"), { ssr: false });
const CountryField = dynamic(() => import("../common/country-field"), {
  ssr: false,
});
const CheckCircleIcon = dynamic(
  () => import("@heroicons/react/24/outline/CheckCircleIcon"),
  {
    ssr: false,
  },
);
const { enqueueSnackbar } = dynamic(() => import("notistack"), { ssr: false });
const CloseButton = dynamic(() => import("../common/close-button"), {
  ssr: false,
});
const PhoneInput = dynamic(() => import("../common/phone-input"), {
  ssr: false,
});
const { isValidPhoneNumber } = dynamic(
  () => import("react-phone-number-input"),
  { ssr: false },
);
// import { DevTool } from "@hookform/devtools";

const validateEmail = require("email-validator");

const ContactForm = ({
  omitSubject = false,
  omitCompany = false,
  omitCountry = false,
  omitPhone = false,
}) => {
  const analytics = useContext(AnalyticsContext);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const form = useForm({
    defaultValues: {
      name: "",
      company: "",
      country: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = form;

  // const [phoneFocused, setPhoneFocused] = useState(false);
  const [defaultCountryCode, setDefaultCountryCode] = useState({});
  const [phoneOverride, setPhoneOverride] = useState(false);

  useEffect(() => {
    if (phoneOverride) {
      trigger("phone");
    }
  }, [phoneOverride]);

  const clearForm = () => {
    form.reset();
    setSent(false);
    setPhoneOverride(false);
  };

  const handleSend = async (
    /** @type {{ name: any; email: any; phone: any; company: any; country: any; subject: any; message: any; }} */ data,
  ) => {
    analytics.action("contact-form-submitted");
    setSending(true);
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify({
          name: data.name,
          company: data.company,
          country: data.country.name,
          email: data.email,
          phone: data.phone,
          subject: data.subject,
          message: data.message,
        }),
      });
      setSending(false);
      if (response.ok) {
        setSent(true);
        analytics.success("contact-form-submitted");
      } else {
        analytics.error("contact-form-submitted", response.statusText);
        enqueueSnackbar(
          "An error occured while sending your message. Please try again.",
          { variant: "error" },
        );
      }
    } catch (err) {
      console.log(err);
      analytics.error("contact-form-submitted", err);
      enqueueSnackbar(
        "An error occured while sending your message. Please try again later.",
        { variant: "error" },
      );
      setSending(false);
    }
  };

  const country = watch("country");

  // Animation on Error

  const controls = useAnimationControls();

  // Declare a motion variant to shake the element along the x axis 3 times.
  const shake = {
    x: [0, -3, 3, -3, 3, -3, 3, 0],
    transition: { duration: 0.3 },
  };

  const { isSubmitting } = useFormState({ control });
  // Animate the element when the error changes.
  useEffect(() => {
    if (
      !isSubmitting &&
      errors
      // (errors.name || errors.email || errors.subject || errors.message || errors.phone || errors.company || errors.country)
    ) {
      controls.start(shake);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (country) {
      setDefaultCountryCode({ defaultCountry: country.code });
    }
  }, [country, setDefaultCountryCode]);

  return (
    <div className="mb-5">
      {!sent ? (
        <form id="contact-form" noValidate onSubmit={handleSubmit(handleSend)}>
          <div className="flex flex-col gap-4">
            <TextField
              id="name"
              label="Name"
              autoComplete="name"
              required
              {...register("name", { required: "Name is required." })}
              error={!!errors.name}
              helperText={errors.name?.message}
              animate={!!errors.name ? controls : {}}
            />
            {!omitCompany && (
              <TextField
                id="company"
                label="Company"
                autoComplete="organization"
                variant="outlined"
                error={!!errors.company}
                {...register("company")}
                helperText={errors.company?.message}
                component={m.div}
                animate={!!errors.company ? controls : {}}
              />
            )}
            {!omitCountry && (
              <CountryField
                country={country}
                label="Country"
                setCountry={(value) => setValue("country", value)}
                defaultFromIp
                {...register("country")}
                error={!!errors.country}
                helperText={errors.country?.message}
                // animate={!!errors.country ? controls : {}}
              />
            )}
            <TextField
              id="email"
              label="Email"
              autoComplete="email"
              variant="outlined"
              error={!!errors.email}
              required
              {...register("email", {
                required: "Email is required.",
                validate: (value) =>
                  validateEmail.validate(value) ||
                  "Please enter a valid email address.",
              })}
              helperText={
                errors.email?.message // || "We will never share your email."
              }
              component={m.div}
              animate={!!errors.email ? controls : {}}
            />
            {!omitPhone && (
              <PhoneInput
                id="phone"
                name="phone"
                label="Phone"
                {...defaultCountryCode}
                control={control}
                rules={{
                  validate: (v) =>
                    phoneOverride ||
                    v === null ||
                    v.length === 0 ||
                    isValidPhoneNumber(v) ||
                    "Phone number seems to be invalid. You can click the button in the box to confirm it.",
                }}
                international
                showOverride={errors.phone?.type === "validate"}
                phoneOverride={phoneOverride}
                setPhoneOverride={setPhoneOverride}
                // addInternationalOption={false}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                animate={!!errors.phone ? controls : {}}
              />
            )}
            {!omitSubject && (
              <TextField
                id="subject"
                label="Subject"
                autoComplete="subject"
                variant="outlined"
                {...register("subject")}
                error={!!errors.subject}
                helperText={errors.subject?.message}
                component={m.div}
                animate={!!errors.subject ? controls : {}}
              />
            )}
            <TextField
              id="message"
              label="Message"
              autoComplete="message"
              className="min-h-40"
              variant="outlined"
              multiline
              required
              {...register("message", { required: "Message is required." })}
              error={!!errors.message}
              helperText={errors.message?.message}
              component={m.div}
              animate={!!errors.message ? controls : {}}
            />
            <div className="h-10 w-full">
              <button
                className="button h-full w-full"
                type="submit"
                disabled={sending}
              >
                {sending ? (
                  <Spinner className="h-6 w-6 fill-white/80 text-white/40" />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center p-4">
          <CloseButton onClick={clearForm} />
          <CheckCircleIcon className="w-24 text-primary-800/80" />
          <h2 className="py-2 text-primary-800">Hang on tight!</h2>
          <h3 className="pb-3 text-center">
            We will get back to you as soon as possible.
          </h3>
        </div>
      )}
      {/* <DevTool control={control} placement="top-right" /> */}
    </div>
  );
};

export default ContactForm;
