import { NextSeo } from "next-seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import HeroSection from "@/components/home/hero-section";
// import React from "react";

// import dynamic from "next/dynamic";

import AboutSection from "@/components/home/about-section";
// const AboutSection = dynamic(() => import("@/components/home/about-section"), {
//   ssr: false,
// });
import WhyUsSection from "@/components/home/why-us-section";
// const WhyUsSection = dynamic(() => import("@/components/home/why-us-section"), {
//   ssr: false,
// });
import ProductsSection from "@/components/home/products-section";
// const ProductsSection = dynamic(
//   () => import("@/components/home/products-section"),
//   { ssr: false },
// );
import MapSection from "@/components/home/map-section";
// const MapSection = dynamic(() => import("@/components/home/map-section"), {
//   ssr: false,
// });
import ContactSection from "@/components/home/contact-section";
// const ContactSection = dynamic(
//   () => import("@/components/home/contact-section"),
//   { ssr: false },
// );

const translationNamespaces = ["common", "nav", "home"];
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, translationNamespaces)),
    },
  };
}

export default function Home() {
  const { t } = useTranslation();
  // const [loaded, setLoaded] = React.useState(false);
  // React.useEffect(() => {
  //   setLoaded(true);
  // }, []);

  return (
    <div className="flex min-h-fit flex-col items-center justify-center">
      <NextSeo
        title="Home"
        description="AlHayat Foodstuff: Your Premier Partner for Quality Frozen Vegetables Worldwide"
        canonical="https://alhayatfoodstuff.com"
        openGraph={{
          url: "https://alhayatfoodstuff.com",
          title: "AlHayat Foodstuff",
          description:
            "AlHayat Foodstuff: Your Premier Partner for Quality Frozen Vegetables Worldwide",
          images: [
            {
              url: "https://alhayatfoodstuff.com/img/og/og-home.jpg",
              width: 1200,
              height: 630,
              alt: "AlHayat Foodstuff Logo",
            },
          ],
          site_name:
            "AlHayat Foodstuff: Your Premier Partner for Quality Frozen Vegetables Worldwide",
        }}
        twitter={{
          handle: "@alhayatfoodstuff",
          site: "@alhayatfoodstuff",
          cardType: "summary_large_image",
        }}
      />
      <HeroSection />
      {/* {loaded && (
        <> */}
      <AboutSection />
      <WhyUsSection />
      <ProductsSection />
      <MapSection />
      <ContactSection />
      {/* </>
      )} */}
    </div>
  );
}
